@import "../../../node_modules/bootstrap/scss/functions";

$primary: #26A3A4;
$primary-light: #92d1d1;
$primary-lighter: #c8e8e8;
$primary-dark: #135152;
$primary-bg-subtle: #a2cec4;
$secondary: #FF8B2E;
$dark: #092829; //#2B2D42;
//$light: #CABAC8;

$enable-gradients: true;

$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 6,
);

@import '../../../node_modules/bootstrap/scss/bootstrap';

.btn-primary {
  color: white;
}

.bg-primary-light {
  background-color: $primary-light;
}

.bg-primary-lighter {
  background-color: $primary-lighter;
}

.bg-primary-dark {
  background-color: $primary-dark;
}

.text-primary-dark {
  color: $primary-dark;
}