/*html {*/
/*    font-size: 14px;*/
/*}*/

html, body {
    height: var(--app-height);
}

.vh-custom {
    /*height: 75vh;*/
    height: 100%;
}